export const STATS = {
    // Home page
    all: {
        anchors: 136,
        borrowers: 17346,
        invoices: 318752,
        disbursed: 25376365742.45,
        aum: 2985745584.0,
    },
    // SaralPay Later
    bnpl: {
        anchors: 24,
        borrowers: 3357,
        invoices: 21083,
        disbursed: 8785528821.78,
        aum: 504722717.0,
    },
    // Vendor Finance
    vendor: {
        anchors: 8,
        borrowers: 83,
        invoices: 820,
        disbursed: 6133661545.34,
        aum: 955822553.0,
    },
    // Supplychain finance
    scf: {
        anchors: 20,
        borrowers: 20,
        invoices: 896,
        disbursed: 10457175783.0,
        aum: 1525200314.0,
    },
};
